import React from "react";
import DesktopMenu from "./component/DesktopMenu";
import MobileMenu from "./component/MobileMenu";

const LockerMenu = (props) => {
  
  return (
    <>
      <DesktopMenu />
      <MobileMenu />
    </>
  );
};

export default LockerMenu;
