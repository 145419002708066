import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import logo from "../../../images/logo.png";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import {
  MenuLink,
  MobileMainMenu,
  DrawerBox,
  DrawerDivider,
  MenuButton,
  CustomBtn,
} from "../styles";
import styled from "@emotion/styled";
import { useContext } from "react";
import { AddresContext, FunctionContext } from "../../../App";


const DataImage = styled.img`
  width: 100%;
  max-width: ${(props) => (props.mw ? props.mw : "90%")};
  margin: ${(props) => (props.m ? props.m : "0px")};
  /* align-items: ${(props) => (props.ai ? props.ai : "center")}; */
  @media (max-width: 899px) {
    max-width: ${(props) => (props.mw899 ? props.mw899 : "")};
  }
  @media (max-width: 599px) {
    max-width: ${(props) => (props.mw599 ? props.mw599 : "")};
    margin: ${(props) => (props.m599 ? props.m599 : "0")};
    display: ${(props) => (props.disp ? props.disp : "")};
    visibility: ${(props) => (props.vis ? props.vis : "")};
  }
`;
const MobileMenu = (props) => {
  const [state, setState] = useState({ left: false });

  const toggleDrawer = (anchor, open) => (event) => {
    console.log("thest: ", anchor + " : " + open);
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const walletAddress = useContext(AddresContext);
  const connectWallet = useContext(FunctionContext);
  return (
    
    <MobileMainMenu>
      <MenuLink href="/" p="15px" sx={{ display: "flex !important" }}>
        <DataImage src={logo} mw="50px" />
      </MenuLink>
      <MenuLink >
          <CustomBtn
            onClick={() => connectWallet()}
          >
            <span className="is-link has-text-weight-bold">
              {walletAddress && walletAddress.length > 0
                ? `Connected: ${walletAddress.substring(
                    0,
                    6
                  )}...${walletAddress.substring(38)}`
                : "Connect Wallet"}
            </span>
          </CustomBtn>
        </MenuLink>
      <MenuButton onClick={toggleDrawer("left", true)}>
        {state["left"] ? (
          <CloseIcon sx={{color:"#fff",fontSize:"30px",zIndex:"9"}}  />
        ) : (
          <MenuIcon sx={{color:"#fff",fontSize:"30px"}} />
        )}
      </MenuButton>
      <Drawer
        anchor="left"
        open={state["left"]}
        onClose={toggleDrawer("left", false)}
      >
        <DrawerBox
          role="presentation"
          onClick={toggleDrawer("left", false)}
          onKeyDown={toggleDrawer("left", false)}
        >
          <DrawerDivider>
            <MenuLink href="/">
              <DataImage src={logo} mw="50px" />
            </MenuLink>
            <Divider style={{ background: "#ffffff" }} />
            <List>
              <MenuLink p="20px 5px 0 10px" href="https://calchain.finance/" target="blank">
                Home
              </MenuLink>
              <MenuLink p="20px 5px 0 10px" href="" target="blank">
                Testnet Dex (Comming soon)
              </MenuLink>
              <MenuLink p="20px 5px 0 10px" href="" target="blank">
                Testnet Explorer
              </MenuLink>
            </List>
          </DrawerDivider>
        </DrawerBox>
      </Drawer>
    </MobileMainMenu>
  );
};

export default MobileMenu;
