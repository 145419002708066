import { useEffect, useState } from "react";
import "./App.css";
import { ethers } from "ethers";
import faucetContract from "./ethereum/faucet";
import logo from "./images/logo.png";
import Faucet from "./images/Faucet.png";
import bg from "./images/bg-1.png";
import Captcha from "./component/index";
import { createContext } from "react";
import { Add } from "@mui/icons-material";
import {
  Button,
  Container,
  Grid,
  Input,
  Link,
  Typography,
} from "@mui/material";
import TelegramIcon from "@mui/icons-material/Telegram";
import TwitterIcon from "@mui/icons-material/Twitter";
import styled from "@emotion/styled";
import LockerMenu from "./component/LockerMenu";
import { MenuLink } from "./component/LockerMenu/styles";

const ContainerWraper = styled(Container)`
  background: url(${bg});
  background-color: #000000;
  color: white;
  background-repeat: no-repeat;
  background-position: center right;
  background-size: contain;
  padding: 0 !important;
  position: relative;
  min-height: calc(100vh);
  @media (max-width: 599px) {
    background-size: cover;
  }
`;
const InputGrid = styled(Grid)`
  background-color: #141414;
  padding: 20px;
  border-radius: 30px;
  width: 100%;
  min-height: 200px;
  display: flex;
  align-items: start;
  justify-content: space-between;
  z-index: 1;
`;
const SocialDiv = styled.div`
  width: 100%;
  position: absolute;
  column-gap: 10px;
  bottom: 0;
  right: 50%;
  transform: translate(50%, 0%);
  display: flex;
  align-items: start;
  justify-content: center;
  z-index: 1;
`;
const InputField = styled.input`
  width: 100%;
  background-color: #303030;
  border-radius: 10px;
  color: #fff;
  padding: 8px 15px;
  font-family: segoepr;
  font-size: 16px;
  box-shadow: none;
  border: none;
  :focus {
    outline: none;
  }
`;
const CustomBtn = styled(Button)`
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  color: #000;
  padding: 11.5px 0;
  font-family: segoepr;
  font-size: 14px;
  font-weight: bolder;
  box-shadow: none;
  border: none;
  :hover {
    background-color: #fff;
    color: #000;
  }
`;
const AbsoluiteImage = styled.img`
  width: 100%;
  position: absolute;
  bottom: ${(props) => (props.b ? props.b : "50%")};
  right: ${(props) => (props.r ? props.r : "0%")};
  transform: ${(props) => (props.tran ? props.tran : "translate(0%,50%)")};
  max-width: ${(props) => (props.mw ? props.mw : "25%")};
  margin: ${(props) => (props.m ? props.m : "0")};
  /* align-items: ${(props) => (props.ai ? props.ai : "center")}; */
  z-index: 0;
  @media (max-width: 999px) {
    max-width: ${(props) => props.mw999};
  }
  @media (max-width: 599px) {
    max-width: ${(props) => (props.mw599 ? props.mw599 : "40%")};
    bottom: ${(props) => (props.b599 ? props.b599 : "0%")};
    right: ${(props) => (props.r599 ? props.r599 : "0%")};
    transform: ${(props) =>
      props.tran599 ? props.tran599 : "translate(0%,0%)"};
    padding: ${(props) => (props.m599 ? props.m599 : "0 0px 0 0")};
  }
`;
export const AddresContext = createContext();
export const FunctionContext = createContext();
function App() {
  const [walletAddress, setWalletAddress] = useState("");
  const [signer, setSigner] = useState();
  const [fcContract, setFcContract] = useState();
  const [withdrawError, setWithdrawError] = useState("");
  const [withdrawSuccess, setWithdrawSuccess] = useState("");
  const [transactionData, setTransactionData] = useState("");
  const [loader, setLoader] = useState(false);
  const [captcha, setCaptcha] = useState(false);

  useEffect(() => {
    getCurrentWalletConnected();
    addWalletListener();
  }, [walletAddress]);

  // const connectWallet = async () => {
  //   if (typeof window != "undefined" && typeof window.ethereum != "undefined") {
  //     try {
  //       /* get provider */
  //       const provider = new ethers.providers.Web3Provider(window.ethereum);
  //       /* get accounts */
  //       const accounts = await provider.send("eth_requestAccounts", []);
  //       /* get signer */
  //       setSigner(provider.getSigner());
  //       /* local contract instance */
  //       setFcContract(faucetContract(provider));
  //       /* set active wallet address */
  //       setWalletAddress(accounts[0]);
  //     } catch (err) {
  //       console.error(err.message);
  //     }
  //   } else {
  //     /* MetaMask is not installed */
  //     console.log("Please install MetaMask");
  //   }
  // };

  const getCurrentWalletConnected = async () => {
    // if (typeof window != "undefined" && typeof window.ethereum != "undefined") {
      // try {
        /* get provider */
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        /* get accounts */
        const accounts = await provider.send("eth_accounts", []);
        if (accounts.length > 0) {
          /* get signer */
          setSigner(provider.getSigner());
          /* local contract instance */
          setFcContract(faucetContract(provider));
          /* set active wallet address */
          setWalletAddress(accounts[0]);
        } else {
          console.log("Connect to MetaMask using the Connect Wallet button");
        }
    //   } catch (err) {
    //     console.error(err.message);
    //   }
    // } else {
    //   /* MetaMask is not installed */
    //   console.log("Please install MetaMask");
    // }
  }

  const addWalletListener = async () => {
    if (typeof window != "undefined" && typeof window.ethereum != "undefined") {
      window.ethereum.on("accountsChanged", (accounts) => {
        setWalletAddress(accounts[0]);
      });
    } else {
      /* MetaMask is not installed */
      setWalletAddress("");
      console.log("Please install MetaMask");
    }
  };

  const getOCTHandler = async () => setCaptcha(true);

  const checkCaptcha = async (value) => {
    if (!value) {
      console.log("empty: ", value);
    } else {
      setLoader(true);
      setWithdrawError("");
      setWithdrawSuccess("");
      try {
        // network: using the Rinkeby testnet
        let provider = await ethers.getDefaultProvider(
          "http://54.90.240.53:8545/"
        );
        // let providerID = await ethers.getChainId();
        let privateKey =
          "aa498c8bf9d34511d0510ac3531667d6c9f874f8418d5ee1db452f35a99abc98";
        let wallet = new ethers.Wallet(privateKey, provider);
        let tx = {
          to: walletAddress,
          value: ethers.utils.parseEther("10"),
        };

        let sendPromise = await wallet.sendTransaction(tx);
        console.log("check sendPromise: ", sendPromise);
        setLoader(false);
        setWithdrawError("You'll get POA within few minutes.");
      } catch (err) {
        console.log("check err: ", err.message);
        setLoader(false);
        setWithdrawError("Something went wrong, please try again later.!");
      }
    }
  };

  return (
    <FunctionContext.Provider >
      <AddresContext.Provider >
        <ContainerWraper maxWidth="xxl">
          <LockerMenu />
          {/* <AbsoluiteImage src={logo} /> */}
          <AbsoluiteImage src={Faucet} alt="Faucet" />
          <SocialDiv>
            <Link href="https://twitter.com/calchaineth" target="blank">
              <TwitterIcon
                sx={{
                  color: "#fff",
                  fontSize: "40px",
                  padding: "5px",
                  border: "1px solid #fff",
                  borderRadius: "10px",
                }}
              />
            </Link>
            <Link href="https://t.me/calchaineth" target="blank">
              <TelegramIcon
                sx={{
                  color: "#fff",
                  fontSize: "40px",
                  padding: "5px",
                  border: "1px solid #fff",
                  borderRadius: "10px",
                }}
              />
            </Link>
          </SocialDiv>
          <Container maxWidth="xl">
            {loader ? (
              <div className="loaderWrapper">
                <div className="loader"></div>
              </div>
            ) : (
              <span />
            )}
            <Grid container>
              <Grid
                container
                item
                xs={12}
                sm={10}
                md={8}
                sx={{
                  display: "flex",
                  flexDiection: "column",
                  alignItems: "start",
                  justifyContent: "start",
                  rowGap: "20px",
                }}
              >
                <Typography variant="h3" color="#fff" fontFamily="segoepr">
                  FAUCET
                </Typography>
                <div className="mt-5">
                  {withdrawError && (
                    <div className="withdraw-error">{withdrawError}</div>
                  )}
                  {withdrawSuccess && (
                    <div className="withdraw-success">{withdrawSuccess}</div>
                  )}{" "}
                </div>
                <InputGrid container item xs={12}>
                  <Grid item xs={12}>
                    <Typography
                      variant="body"
                      color="#fff"
                      fontFamily="segoepr"
                    >
                      Enter your wallet address to get $CalTest token
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={9.5}>
                    <InputField
                      type="text"
                      placeholder="Enter your wallet address (0x...)"
                      defaultValue={walletAddress}
                    />
                  </Grid>
                  <Grid item xs={6} sm={2}>
                    <CustomBtn
                      onClick={checkCaptcha}
                    >
                      GET TOKENS
                    </CustomBtn>
                  </Grid>
                  {/* <article className="panel is-grey-darker">
                    {captcha ? <Captcha checkVal={checkCaptcha} /> : <span />}
                  </article> */}
                </InputGrid>
                <div>
                <Typography variant="h3" color="#fff" fontFamily="segoepr" sx={{zIndex:"1"}}>
                  How Does this work?
                </Typography>
                <br></br>
                <Typography variant="body" color="#fff" fontFamily="segoepr" sx={{zIndex:"1"}}>
                  $CalTest tokens are issued as Calc20 testnet tokens
                </Typography>
                </div>
              </Grid>
            </Grid>
          </Container>
        </ContainerWraper>
      </AddresContext.Provider>
    </FunctionContext.Provider>
  );
}

export default App;
