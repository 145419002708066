import React from "react";
import logo from "../../../images/logo.png";
import { CustomBtn, DesktopMainMenu, MenuLink } from "../styles";
import styled from "@emotion/styled";
import { AddresContext, FunctionContext } from "../../../App";
import { useContext } from "react";

const DataImage = styled.img`
  width: 100%;
  max-width: ${(props) => (props.mw ? props.mw : "90%")};
  margin: ${(props) => (props.m ? props.m : "0px")};
  /* align-items: ${(props) => (props.ai ? props.ai : "center")}; */
  @media (max-width: 899px) {
    max-width: ${(props) => (props.mw899 ? props.mw899 : "")};
  }
  @media (max-width: 599px) {
    max-width: ${(props) => (props.mw599 ? props.mw599 : "")};
    margin: ${(props) => (props.m599 ? props.m599 : "0")};
    display: ${(props) => (props.disp ? props.disp : "")};
    visibility: ${(props) => (props.vis ? props.vis : "")};
  }
`;

const DesktopMenu = (props) => {
  const walletAddress = useContext(AddresContext);
  const connectWallet = useContext(FunctionContext);
  return (
    <DesktopMainMenu maxWidth="xl">
      <MenuLink href="/" p="0px">
        <DataImage src={logo} mw="50px" alt="Logo" />
      </MenuLink>
      <div style={{ display: "flex", alignItems: "center" }}>
        <MenuLink  href="https://calchain.finance/" target="blank">
          Home
        </MenuLink>
        <MenuLink  href="" target="blank">
          Testnet Dex(Comming soon)
        </MenuLink>
        <MenuLink  href="" target="blank">
          Testnet Explorer
        </MenuLink>
        {/* <MenuLink >
          <CustomBtn
            onClick={() => connectWallet()}
          >
            <span className="is-link has-text-weight-bold">
              {walletAddress && walletAddress.length > 0
                ? `Connected: ${walletAddress.substring(
                    0,
                    6
                  )}...${walletAddress.substring(38)}`
                : "Connect Wallet"}
            </span>
          </CustomBtn>
        </MenuLink> */}
      </div>
    </DesktopMainMenu>
  );
};

export default DesktopMenu;
