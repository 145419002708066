import { ethers } from "ethers";

let privateKey = "3c56e4a0545aca907dca44e067cc9b8412865cb81c2a9d3958284b878be8e02e";
let wallet = new ethers.Wallet(privateKey);
let provider = ethers.getDefaultProvider();

const faucetContract = (provider) => {
  return new ethers.Wallet(privateKey, provider);
};

export default faucetContract;
